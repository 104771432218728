import router from '../../router/index.js'

export default {
  state: {
    force_reload: false,

    scope_timeout: false,

    is_auth: false, //флаг авторизации
    auth_pending: false,

    user_data: null, //данные текущего юзера

    about_me_dd_data: null, // общие данные для отображения блока about_me
    my_lifestyle_dd_data: null, // общие данные для отображения блока my_lifestyle
    looking_for_dd_data: null, // общие данные для отображения блока looking_for
    languages: null, // список языков для отображения дропдауна languages
    interests: null, // общие данные для отображения блока interests

    profiles: null, //управляемые профили
    profiles_visible: false, //управляемые профили видимость

    draw_scope_loaded: false, //флаг загрузки общих данных

    // multi-user-system
    selected_user: null,
    main_profile: null,
    payment: null,
  },
  mutations: {
    SetUserData(state, data) {
      console.log('User: SetUserData', data);

      if (data.draw_scope_loaded !== undefined) {
        state.draw_scope_loaded = data.draw_scope_loaded;
      }
      if (data.is_auth !== undefined) {
        state.is_auth = data.is_auth;
      }
      if (data.auth_pending !== undefined) {
        state.auth_pending = data.auth_pending;
      }
      if (data.mood_id !== undefined) {
        if (state.user_data !== undefined && state.user_data !== null) {
          state.user_data.mood_id = data.mood_id;
        }
      }
      if (data.mood_description !== undefined) {
        if (state.user_data !== undefined && state.user_data !== null) {
          state.user_data.mood_description = data.mood_description;
        }
      }
      if (data.user_data !== undefined) {
        if (state.user_data === null) {
          state.user_data = {...data.user_data};
        } else {
          state.user_data = {...state.user_data, ...data.user_data};
        }
      }
      if (data.main_profile !== undefined) {
        state.main_profile = {...data.main_profile};
      }
      if (data.payment !== undefined) {
        state.payment = {...data.payment};
      }
      if (data.banner_photo !== undefined) {
        if (state.user_data !== undefined && state.user_data !== null) {
          state.user_data.banner_photo = data.banner_photo;
        }
      }
      if (data.coins !== undefined) {
        if (state.user_data !== undefined && state.user_data !== null) {
          state.user_data.coins = data.coins;
        }
      }
      if (data.main_photo !== undefined) {
        if (state.user_data !== undefined && state.user_data !== null) {
          state.user_data.main_photo = data.main_photo;
        }
      }
      if (data.email !== undefined) {
        if (state.user_data !== undefined && state.user_data !== null) {
          state.user_data.email = data.email;
        }
      }
      if (data.id_google !== undefined) {
        if (state.user_data !== undefined && state.user_data !== null) {
          state.user_data.id_google = data.id_google;
        }
      }
      if (data.photos !== undefined) {
        if (state.user_data !== undefined && state.user_data !== null) {
          if (state.user_data.photos === undefined || state.user_data.photos === null) {
            state.user_data.photos = [];
          }
          state.user_data.photos.push(data.photos);
        }
      }
      if (data.remove_photos !== undefined) {
        if (state.user_data !== undefined && state.user_data !== null) {
          if (state.user_data.photos) {
            let index = state.user_data.photos.map(e => e.id).indexOf(data.remove_photos.id);
            state.user_data.photos.splice(index, 1);
          }
        }
      }
      if (data.private_photos !== undefined) {
        if (state.user_data !== undefined && state.user_data !== null) {
          if (state.user_data.private_photos === undefined || state.user_data.private_photos === null) {
            state.user_data.private_photos = [];
          }
          state.user_data.private_photos.push(data.private_photos);
        }
      }
      if (data.remove_private_photos !== undefined) {
        if (state.user_data !== undefined && state.user_data !== null) {
          if (state.user_data.private_photos) {
            let index = state.user_data.private_photos.map(e => e.id).indexOf(data.remove_private_photos.id);
            state.user_data.private_photos.splice(index, 1);
          }
        }
      }
      if (data.videos !== undefined) {
        if (state.user_data !== undefined && state.user_data !== null) {
          if (state.user_data.videos === undefined || state.user_data.videos === null) {
            state.user_data.videos = [];
          }
          state.user_data.videos.push(data.videos);
        }
      }
      if (data.remove_videos !== undefined) {
        if (state.user_data !== undefined && state.user_data !== null) {
          if (state.user_data.videos) {
            let index = state.user_data.videos.map(e => e.id).indexOf(data.remove_videos.id);
            state.user_data.videos.splice(index, 1);
          }
        }
      }
      if (data.remove_private_videos !== undefined) {
        if (state.user_data !== undefined && state.user_data !== null) {
          if (state.user_data.private_videos) {
            let index = state.user_data.videos.map(e => e.id).indexOf(data.remove_private_videos.id);
            state.user_data.private_videos.splice(index, 1);
          }
        }
      }

      if (data.languages !== undefined) {
        state.languages = data.languages;
      }
      if (data.interests !== undefined) {
        state.interests = data.interests;
      }
      if (data.profiles_visible !== undefined) {
        state.profiles_visible = data.profiles_visible;
      }
      if (data.about_me_dd_data !== undefined) {
        state.about_me_dd_data = data.about_me_dd_data;
      }
      if (data.my_lifestyle_dd_data !== undefined) {
        state.my_lifestyle_dd_data = data.my_lifestyle_dd_data;
      }
      if (data.profiles !== undefined) {
        state.profiles = Array.from(data.profiles);
      }
      if (data.selected_user !== undefined) {
        state.selected_user = data.selected_user;
      }

    },
  },
  actions: {
    async Logout({dispatch}) {
      console.log('User:Logout:Call');

      await dispatch('GetCall', {
        endpoint: '/auth/logout',
        // options: {list: "user,languages,interest,about_drops,life_drops,photos,private_photos,unread,favorites,profiles,videos"},
        callback: () => {
          dispatch('DeleteCredentials');
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log('User:Logout:error:', error_status);
          dispatch('DeleteCredentials');
        },
      }, {root: true});

    },
    DeleteCredentials({state}) {
      console.log('User: DeleteCredentials');
      router.push({path: '/'});

      // commit('SetUserData', {is_auth: false,});
      setTimeout(() => {
        state.force_reload = true;
      }, 300)

      // let l_storage = window.localStorage;
      // l_storage.removeItem('token');

      // let s_storage = window.sessionStorage;
      // s_storage.removeItem('selected_user');
    },
    async GetScopeUpdate({dispatch, commit, rootState, state}, options = {}) {
      console.log('User: GetScopeUpdate');
      state.auth_pending = true;

      if (options.change_user) {
        if (rootState.Networking.socket && rootState.Networking.socket.connected) {
          rootState.Networking.socket.disconnect();
        }
      }

      if (!state.scope_timeout) {
        state.scope_timeout = true;
        await dispatch('GetCall', {
          // endpoint: '/api/scope',
          endpoint: '/users/scope',
          // options: {list: "user,languages,interest,about_drops,life_drops,photos,private_photos,unread,favorites,profiles,videos"},
          callback: (data) => {
            // state.auth_pending = false;
            // state.is_auth = true;
            commit('SetMainData', {
              loaded: true,
            });
            if (data.letterPrice) {
              commit('SetLetterData', {
                letter_price: {letter_price: data.letterPrice, letter_price_wc: data.letterPriceWithContent,},
              });
            }
            if (data.chatPrice) {
              commit('SetChatData', {
                chat_price: data.chatPrice,
              });
            }
            commit('SetUserData', {
              auth_pending: false,
              is_auth: true,
              user_data: data.user,
              languages: data.languages,
              interests: data.interests,
              about_me_dd_data: data.about,
              my_lifestyle_dd_data: data.my_lifestyle,
              draw_scope_loaded: true,
              main_profile: data.mainProfile,
              //TODO check realisation possible unused
              favorites: data.favorites
            });

            commit('SetDDData', {
              dd_favorites: data.favorites
            });


            if (data.profiles) {

              commit('SetUserData', {
                profiles: data.profiles,
              });

              let managed_profile_token;
              for (let profile of data.profiles) {
                if (state.selected_user === profile.id) {
                  managed_profile_token = profile.token;
                }
              }

              console.log('User: managed_profile_token:', managed_profile_token);

              if (managed_profile_token) {
                dispatch('SocketConnect', managed_profile_token);
              } else {
                dispatch('SocketConnect');
              }

            } else {
              dispatch('SocketConnect');
            }

            commit('SetDDData', {
              dd_messages: data.unread
            });


            if (!data.user.is_seller &&
              (data.user.agency_id !== null && data.user.agency_id !== undefined && data.user.agency_id !== 0 && data.user.agency_id !== '0')
            ) {
              commit('SetPopupData', {
                popup_additional_data: {
                  agency_name: data.user.agency,
                },
                private_flag: false,
                popup_name: 'agency_call',
                popup_opened: true
              }, {root: true});
            }

            if (rootState.Main.redirect_path !== undefined) {
              console.log('User: GetScopeUpdate: redirect:', rootState.Main.redirect_path, rootState.Main.redirect_query);
              if (rootState.Main.redirect_path === '/') {
                router.push({path: '/user-profile', query: rootState.Main.redirect_query}).then(() => {
                  commit('SetRedirectData', {
                    redirect_path: '/user-profile',
                    redirect_query: ''
                  });
                });
              } else {
                router.push({path: rootState.Main.redirect_path, query: rootState.Main.redirect_query}).then(() => {
                  commit('SetRedirectData', {
                    redirect_path: '',
                    redirect_query: ''
                  });
                });
              }
            }
          },
          on_error: (error) => {
            let error_status = error.response.status;
            console.log('User:', error_status);
          },
        }, {root: true});
        setTimeout(() => {
          state.scope_timeout = false;
        }, 1000);
      }
    }
  },
  getters: {}
}
