<template>
  <li>
    <figure>
      <div class="media">
        <picture class="media-preview" @click="ViewPhoto">
          <!--          <source srcset="/pic/photo.webp 1x, /pic/photo@2x.webp 2x" type="image/webp">-->
          <!--          <source srcset="/pic/photo.jpg 1x, /pic/photo@2x.jpg 2x" type="image/jpeg">-->
          <img :src="static_server + '/' + photo.preview" alt="">
        </picture>
        <button type="button" class="media-remove" @click="Delete"></button>
      </div>
      <figcaption class="media-caption">
        <div class="scrollbar-inner">{{ photo.description }}</div>
      </figcaption>
    </figure>
  </li>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "PhotoListElement",
  props: {
    photo: Object,
    private: {
      type: Boolean,
      default: false,
    },
    index:Number
  },
  computed: {
    ...mapState({
      static_server: state => state.Main.static_server,
      photos: state => state.User.user_data.photos,
      private_photos: state => state.User.user_data.private_photos,
    }),
  },
  methods: {
    ...mapMutations(['SetPopupData', 'SetUserData',]),
    ...mapActions(['DeleteCall']),

    ViewPhoto() {
      this.SetPopupData({
        photo_view_data: this.private ? this.private_photos : this.photos,
        photo_view_index: this.index,
        private_flag: false,
        popup_name: 'view_photo',
        popup_opened: true,
      })
    },
    Delete() {
      let endpoint = '/users/photos/' + this.photo.id;
      if(this.private){
        endpoint = '/users/private_photos/' + this.photo.id;
      }

      this.DeleteCall({
        endpoint: endpoint,
        options: '',
        callback: () => {
          this.SetUserData({ remove_photos: this.photo });
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log(error_status);
        },
      })
    },
  }
}
</script>

<style scoped>

</style>