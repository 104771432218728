// import router from '../../router/index.js'
//
import router from "@/router";

export default {
  state: {
    v: '0.4.0.000b',
    // debug: true,
    debug: false,
    // api_server: 'https://api2.dating.mir.digital:8082',
    // socket_server: 'https://api2.dating.mir.digital:8082',
    // api_server: 'https://api.dating.wedding:8082',
    // socket_server: 'https://api.dating.wedding:8082',
    route_server: 'https://api.dating.wedding:34001/access',
    static_server: 'https://cdn.dating.wedding',
    dns_server: 'https://dns.quad9.net:5053/dns-query?name=entrypoint.dating.wedding&type=txt',
    telegram_login:'DatingWeddingBot',
    google_cid: '679142098837-ck1s9pg102gj96o40c4hkkl8ph4d32hv.apps.googleusercontent.com',
    api_server: undefined,
    reserve_api_server: undefined,
    socket_server: undefined,
    reload_flag: false,
    fullscreen_page: false,
    url_query: [],
    redirect_path: undefined,
    redirect_query: undefined,
    month: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ],
    loaded: false,
    hide_loader: false,
  },
  mutations: {
    SetMainData(state, data) {
      console.log('Main:SetMainData:', data);
      if (data.reserve_api_server && data.reserve_api_server !== '') {
        state.reserve_api_server = data.reserve_api_server
      }
      if (data.api_server && data.api_server !== '') {
        state.api_server = data.api_server
        state.socket_server = data.api_server
      }
      if (data.hide_loader !== undefined) {
        state.hide_loader = data.hide_loader
      }
      if (data.loaded !== undefined) {
        state.loaded = data.loaded
      }
    },
    SetRedirectData(state, data) {
      console.log('Main:SetRedirectData:', data);
      if (data.redirect_path !== '') {
        state.redirect_path = data.redirect_path
      } else {
        state.redirect_path = undefined
      }
      if (data.redirect_query !== '') {
        state.redirect_query = data.redirect_query
      } else {
        state.redirect_query = undefined
      }
    },
    SetReloadFlag(state, reload) {
      state.reload_flag = reload;
    },
    SetFullScreen(state, status) {
      state.fullscreen_page = status;
    },
    // UpdateQuery(add_query){
    //   let query = router.currentRoute._value.query;
    //   this.$router.push({ query: {...query, ...add_query }});
    // }
    FillQueryArray(state, array) {
      state.url_query = Array.from(array);
    },
    ClearQueryArray(state) {
      state.url_query = [];
    }
  },
  actions: {
    async SetUrlQuery({state}, query) {
      if (query) {
        if (query.add) {
          query.add.forEach((a) => {
            if (state.url_query.every((e) => e.key !== a.key)) {
              state.url_query.push(a);
            } else {
              const a_index = state.url_query.findIndex((e) => e.key === a.key);
              state.url_query[a_index] = a.val;
            }
          })
        }
        if (query.rm) {
          query.rm.forEach((d) => {
            const a_index = state.url_query.findIndex((e) => e.key === d.key);
            state.url_query.splice(a_index, 1);
          })
        }
        let query_object = {};
        state.url_query.forEach((e) => {
          query_object[e.key] = e.val;
        });
        // console.log('router.path', router.currentRoute._value.path)
        router.replace({path: router.currentRoute._value.path, query: query_object});
      }
    }
  },
  getters: {}
}
