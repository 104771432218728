<template>
  <dialog class="popup" id="add-photos"
          :class="is_open ? 'is-opened' : ''">
    <div class="popup-box" style="max-width: 480px;">
      <button type="button" class="popup-close" @click="hide"></button>
      <div class="popup-header">Add private photos</div>
      <form class="form">
        <div class="popup-content">
          <!--          <div class="popup-icons">-->
          <!--            <img src="img/facebook.svg" alt="">-->
          <!--          </div>-->
          <fieldset>
            <label>Select Photo</label>
            <div class="popup-image default-photo" v-if="file_src">
              <Cropper
                  :src="file_src"
                  @change="change"
                  :stencil-props="{aspectRatio: 7/4,}"
                  image-restriction="none"
              />
              <!--              <img :src="file_src">-->
            </div>
            <div class="textfield-clear file-input-holder" :class="errors.file_src?'error':''">
              <input type="file" class="file-input" @change="FileAdded" accept=".png,.jpg">
              <span v-if="file === null">*.png,*.jpg</span>
              <span v-else class="file-selected">{{ file.name }}</span>
              <button v-if="file !== null" type="button" @click="ClearFile"></button>
            </div>
          </fieldset>
          <fieldset>
            <label>Enter photo description</label>
            <div class="textfield-clear">
              <input type="text" class="textfield" :class="errors.description?'error':''" v-model="description">
              <button type="button" v-if="description.length > 0" @click="ClearDescription"></button>
            </div>
          </fieldset>
          <fieldset>
            <label>Enter photo price</label>
            <div class="textfield-clear">
              <input type="number" class="textfield number-arrow-hide" :class="errors.price?'error':''" v-model="price">
              <button type="button" v-if="price.length > 0" @click="ClearPrice"></button>
            </div>
          </fieldset>
          <!--          <button type="button" class="button-link button-link-add">
                      <i></i>
                      <span>Add more link</span>
                    </button>-->
        </div>
        <div class="popup-bottom">
          <div class="popup-bottm-buttons">
            <div class="button" @click="Save">Save photos</div>
          </div>
        </div>
      </form>
      <div class="popup-cover" v-if="block">
        <div class="spinner"></div>
      </div>
    </div>
  </dialog>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "PAddPhotosPrivate",
  data: function () {
    return {
      is_open: false,
      description: '',
      file: null,
      file_src: null,
      price: '',
      image: null,
      block: false,

      errors: {
        description: false,
        file_src: false,
        price: false
      }
    }
  },
  computed: {
    ...mapState({
      is_auth: state => state.User.is_auth
    }),
  },
  mounted() {
    setTimeout(() => {
      this.is_open = true;
    }, 100);
  },
  methods: {
    ...mapMutations(['SetPopupData', 'SetUserData']),
    ...mapActions(['PostCall',]),

    FileAdded(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (files.length > 0) {
        this.file = files[0];
        this.file_src = URL.createObjectURL(this.file);
        this.errors.file_src = false;
      }
    },
    change({coordinates, canvas}) {
      console.log(coordinates, canvas);
      canvas.toBlob(blob => this.image = blob, 'image/png');
    },
    ClearFile() {
      this.file = null
    },

    ClearDescription() {
      this.description = ''
    },
    ClearPrice() {
      this.price = ''
    },

    Save() {
      if(this.image) {
        this.block = true;

        // let _file = this.file;
        let _file = this.image;

        const formData = new FormData();

        if (this.CheckInputs()) {
          formData.append('type', 'photos');
          formData.append('description', this.description);
          formData.append('file', _file, 'photo.png');
          formData.append('price', this.price);

          this.PostCall({
            endpoint: '/users/private_photos',
            options: formData,
            callback: (data) => {
              if (data) {
                this.SetUserData({private_photos: data});
              }
              this.hide();
            },
            on_error: (error) => {
              let error_status = error.response.status;
              console.log(error_status);
            },
          })
        }
      }else{
        this.SetPopupData({
          popup_additional_data: {
            text: 'Please select image',
          },
          private_flag: false,
          popup_name: 'error',
          popup_opened: true,
        })
      }
    },

    CheckInputs() {
      let ret = true;

      if (this.file_src === null || this.file_src === undefined) {
        ret = false;
        this.errors.file_src = true;
      }
      if (this.description === null || this.description === '') {
        ret = false;
        this.errors.description = true;
      } else {
        this.errors.description = false;
      }
      if (this.price === null || this.price === '' || this.price === '0' || this.price === 0 || parseInt(this.price) < 0) {
        ret = false;
        this.errors.price = true;
      } else {
        this.errors.price = false;
      }

      return ret;
    },

    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetPopupData({popup_opened: false});
      }, 300);
    },
  },
  watch: {
    is_auth(newVal) {
      if (newVal === false) {
        this.hide();
      }
    },
  },

}
</script>

<style scoped>

</style>
