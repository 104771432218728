<template>
  <dialog class="popup" id="add-photos"
          :class="is_open ? 'is-opened' : ''">
    <div class="popup-box" style="max-width: 480px;">
      <button type="button" class="popup-close" @click="hide"></button>
      <div class="popup-header">Add Background Image</div>
      <form class="form">
        <div class="popup-content">
          <fieldset>
            <label>Select Image</label>
            <div class="popup-image" v-if="file_src">
              <img :src="file_src">
            </div>
            <div class="textfield-clear file-input-holder">
              <input type="file" class="file-input" @change="FileAdded" accept=".png,.jpg">
              <span v-if="file === null">*.png,*.jpg</span>
              <span v-else class="file-selected">{{ file.name }}</span>
              <button v-if="file !== null" type="button" @click="ClearFile"></button>
            </div>
          </fieldset>
        </div>
        <div class="popup-bottom">
          <div class="popup-bottm-buttons">
            <div class="button" @click="Save">Save Background</div>
          </div>
        </div>
      </form>
    </div>
  </dialog>
</template>

<script>

import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "PAddBg",
  data: function () {
    return {
      is_open: false,
      file: null,
      file_src: null,
    }
  },
  computed: {
    ...mapState({
      is_auth: state => state.User.is_auth
    }),
  },
  mounted() {
    setTimeout(() => {
      this.is_open = true;
    }, 100);
  },
  methods: {
    ...mapMutations(['SetPopupData', 'SetUserData']),
    ...mapActions(['PostCall',]),


    FileAdded(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (files.length > 0) {
        this.file = files[0];
        this.file_src = URL.createObjectURL(this.file);
      }
    },


    ClearFile() {
      this.file = null
    },

    Save(e) {
      e.preventDefault();
      if (this.file) {
        let _file = this.file;

        const formData = new FormData();

        formData.append('type', 'background');
        formData.append('file', _file);

        this.PostCall({
          endpoint: '/users/update_image',
          options: formData,
          callback: (data) => {
            if (data) {
              this.SetUserData({banner_photo: data.path});
            }
            this.hide();
          },
          on_error: (error) => {
            let error_status = error.response.status;
            console.log(error_status);
            this.hide();
          },
        })
      } else {
        this.SetPopupData({
          popup_additional_data: {
            text: 'Please select image',
          },
          private_flag: false,
          popup_name: 'error',
          popup_opened: true,
        })
      }
    },


    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetPopupData({popup_opened: false});
      }, 300);
    },
  },
  watch: {
    is_auth(newVal) {
      if (newVal === false) {
        this.hide();
      }
    },
  },
}
</script>

<style scoped>

</style>
