<template>
  <section class="box open-box"
           :class="is_opened ? 'is-opened':''">
    <div class="box-header">
      <div class="box-header-item">
        <h3>Add your photos</h3>
      </div>
      <div class="box-header-item">
        <button type="button" class="button-link button-link-add" @click="Photo_File_Add"><i></i><span>Add photo</span></button>
        <div class="close-btn" @click="ToggleOpened"></div>
      </div>
    </div>
    <div class="box-content">
      <ul class="media-list media-photo" v-if="photos">
        <template v-for="(value, index) in photos" v-bind:key="index">
          <photo-list-element :photo="value" :index="index"/>
        </template>
      </ul>
    </div>
  </section>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import PhotoListElement from "../../Misc/PhotoListElement";

export default {
  name: "UP_Photos",
  components: {
    PhotoListElement
  },
  data: () => {
    return {
      is_opened: false,
    }
  },
  computed: {
    ...mapState({
      photos: state => state.User.user_data.photos,
    }),
  },

  methods: {
    ...mapMutations(['SetPopupData',]),
    GoToPreview() {

    },
    Photo_File_Add() {
      this.SetPopupData({
        private_flag: false,
        popup_name: 'add_photo',
        popup_opened: true
      })
    },
    ToggleOpened() {
      this.is_opened = !this.is_opened;
    },
  },

}
</script>

<style scoped>

</style>