<template>
  <header class="header">
    <div class="container" v-if="is_auth">
      <router-link to="/user-profile" class="logo"></router-link>
      <ul class="header-links">
        <li>
          <div class="header-link header-links-mood" @click="OpenMood">
            <span>My mood</span>
          </div>
        </li>
        <li>
          <div class="header-link header-links-filter" @click="OpenSearch">
            <span>Filter</span>
          </div>
        </li>
      </ul>
      <ul class="header-bar">
        <li class="header-bar-chat-call">
          <router-link to="/chat" class="header-link header-bar-chat" @mouseenter="OpenMessages" title="Chat">
            <template v-if="dd_messages">
              <span v-if="dd_messages.length>0">{{ dd_messages.length }}</span>
            </template>
          </router-link>
        </li>
        <!--
                <li class="header-bar-notifications-call">
                  <div class="header-link header-bar-notifications" @mouseenter="OpenNotification" @click="TEMPALERT">
                    <span>3</span>
                  </div>
                </li>
        -->
        <li class="header-bar-favorites-call">
          <router-link to="/favorites" class="header-link header-bar-fav" @mouseenter="OpenFavorites" title="Favorites">
            <template v-if="dd_favorites && dd_favorites.length>0">
              <span v-if="dd_favorites.length>0">{{ dd_favorites.length }}</span>
            </template>
          </router-link>
        </li>
      </ul>
      <!--      <a href="#" class="button">Boost<span>&nbsp;account</span></a>-->
      <div class="header-user" :class="profiles && profiles.length>0 ? 'manager':''" @mouseenter="OpenProfiles">
        <picture v-if="user_data && user_data.main_photo">
          <img :src="static_server + '/' + user_data.main_photo" alt="">
        </picture>
        <div class="no-user-img" v-else></div>
<!--        <div class="no-user-img"></div>-->

        <h1 v-if="user_data">{{ user_data.user_name }}</h1>
      </div>
      <button type="button" class="header-nav-button" @click="OpenMenu">
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
    <div class="container" v-else>
      <router-link to="/" class="logo"></router-link>
    </div>
  </header>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "Header",
  computed: {
    ...mapState({
      static_server: state => state.Main.static_server,
      user_data: state => state.User.user_data,
      profiles: state => state.User.profiles,
      dd_messages: state => state.Dropdowns.dd_messages,
      dd_favorites: state => state.Dropdowns.dd_favorites,
      is_auth: state => state.User.is_auth,
    }),
  },
  methods: {
    ...mapMutations(['SetPopupData', 'SetDDData', 'SetAlertsData']),
    //...mapActions(['PutCall']),

    TEMPALERT() {
      this.SetAlertsData({
        alert: {
          state: 'low',
          type: 'system',
          header: 'Warning:',
          text: 'you don\'t have enough cash',
          link_name: 'Refill',
          link: ''
        }
      });
      this.SetAlertsData({
        alert: {
          state: 'low',
          type: 'message',
          img: './pic/avatar.png',
          header: 'Yana, 25',
          text: 'Hello. Do You want talk about something interesting talk about something interesting',
          link_name: 'Reply',
          link: ''
        }
      });
    },
    OpenMood() {
      this.SetPopupData({
        private_flag: false,
        popup_name: 'mood',
        popup_opened: true
      })
    },
    OpenSearch() {
      this.SetPopupData({
        private_flag: false,
        popup_name: 'search',
        popup_opened: true
      })
    },
    OpenMenu() {
      this.SetDDData({
        dd_name: 'menu',
        position_target: {hover: 'header-nav-button', x: 'header-nav-button', y: 'header'},
        dd_opened: true,
      })
    },
    OpenNotification() {
      this.SetDDData({
        dd_name: 'notifications',
        position_target: {hover: 'header-bar-notifications-call', x: 'header-bar-notifications', y: 'header', deltaX: 20},
        dd_opened: true,
      })
    },
    OpenFavorites() {
      if (this.dd_favorites && this.dd_favorites.length > 0) {
        this.SetDDData({
          dd_name: 'favorites',
          position_target: {hover: 'header-bar-favorites-call', x: 'header-bar-fav', y: 'header', deltaX: 20},
          dd_opened: true,
        })
      }
    },
    OpenMessages() {
      if (this.dd_messages) {
        if (this.dd_messages.length > 0) {
          this.SetDDData({
            dd_name: 'messages',
            position_target: {hover: 'header-bar-chat-call', x: 'header-bar-chat', y: 'header', deltaX: 20},
            dd_opened: true,
          })
        }
      }
    },
    OpenProfiles() {
      if (this.profiles && this.profiles.length > 0) {
        this.SetDDData({
          dd_name: 'profiles',
          position_target: {hover: 'header-user', x: 'header-user', y: 'header'},
          dd_opened: true,
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
